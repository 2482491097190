
import { computed, defineComponent, onMounted, ref } from "vue";
import Endereco from "./Endereco.vue";
import Entrega from "./Entrega.vue";
import EntregaPa from "./EntregaPa.vue";
import Resumo from "./Resumo.vue";
import { useRouter } from "vue-router";
import { fecharPedido, installControl } from "./shared";
import Totais from "./Totais.vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    Endereco,
    Entrega,
    EntregaPa,
    Resumo,
    Totais,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const control = installControl();

    const toast = useToast();
    const saving = ref(false);

    const block = computed(() => {
      if (store.state.user?.pontoApoioCompra) {
        return false;
      }
      return !control.pedidoInput.entrega?.tipoEntrega || saving.value;
    });

    return {
      block,
      saving,
      loading: control.loadingItensCarrinho,
      control,
      async fecharPedido() {
        if (!store.state.carrinhoItens.length) {
          return;
        }
        saving.value = true;
        try {
          const entrega = control.pedidoInput.entrega;
          if (!entrega?.tipoEntrega) {
            toast.add({
              severity: "warn",
              summary: "Escolha uma forma de entrega",
              life: 2_000,
            });
            return;
          }
          if (
            entrega?.tipoEntrega.transportadora === "correios" &&
            !entrega.servico
          ) {
            toast.add({
              severity: "warn",
              summary: "Escolha um serviço de entrega",
              life: 2_000,
            });
            return;
          }
          const id = await fecharPedido(control);
          router.push({
            name: "pedido-checkout",
            params: {
              id,
            },
          });
        } finally {
          saving.value = false;
        }
      },
      voltar() {
        router.push({ name: "cart" });
      },
    };
  },
});
