<template>
  <div :class="getClass()">
    <div v-ripple @click="selecionar" class="p-ripple">
      <div v-if="!principal">
        <p-button
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-plain p-mr-3"
          @click.stop.prevent="$emit('excluir')"
        />
      </div>
      <div>
        <b>{{ endereco.contato }}</b>
      </div>
      <div>
        {{ endereco.logradouro }},
        {{ endereco.enderecoNumero }}
      </div>
      <div v-if="endereco.complemento">
        {{ endereco.complemento }}
      </div>
      <div v-if="endereco.referencia">
        {{ endereco.referencia }}
      </div>
      <div>
        {{ endereco.bairro }}
      </div>
      <div>{{ endereco.cep }} {{ endereco.cidade }} - {{ endereco.uf }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { useBuscaCep } from "@/composables/cep";
import { PessoaEndereco } from "@/services/PessoaService";
import { getService, User } from "@/services/UserService";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { useChecketouControl } from "./shared";

export default defineComponent({
  emits: ["excluir"],
  props: {
    endereco: {
      type: Object as PropType<PessoaEndereco>,
      required: true,
    },
    principal: {
      type: Boolean,
      default: false,
    },
  },
  setup({ endereco, principal }) {
    const control = useChecketouControl();

    return {
      control,
      getClass() {
        return {
          "p-col-12": true,
          "p-md-6": true,
          "endereco-card": true,
          "endereco-card-checked": principal
            ? !control.endereco.value
            : control.endereco?.value?.id === endereco.id,
        };
      },
      selecionar() {
        control.endereco.value = principal ? undefined : endereco;
      },
    };
  },
});
</script>

<style scoped lang="scss">
.endereco-card {
  > div {
    border: 2px solid #e9ecef;
    border-radius: 7px;
    // margin: 0.5em;
    padding: 0.7em;
    cursor: pointer;
    position: relative;
  }
  .p-button-text {
    position: absolute;
    right: 5px;
    z-index: 1;
  }
}
.endereco-card-checked > div {
  border: 2px solid #007be5;
  background: #eef1f5;
}
</style>