<template>
  <p-card>
    <template #title>Resumo</template>
    <template #content>
      <p-progress-spinner v-if="loading" />
      <div v-else>
        {{ pedidoInput }}
      </div>
    </template>
  </p-card>
</template>

<script lang="ts">
import { getService, User } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    const control = useChecketouControl();

    return {
      loading: control.loading,
      pedidoInput: control.pedidoInput,
    };
  },
});
</script>
