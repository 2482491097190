<template>
  <p-card>
    <template #title> Forma de Entrega </template>
    <template #content>
      <p-progress-spinner v-if="loading" />
      <template v-else-if="pa">
        <div class="info">
          <label> Ponto de Apoio: </label>
          <span>{{ pa.apelido }}</span>
        </div>
        <div class="info">
          <label>Endereço:</label>
          <span
            >{{ pa.logradouro }} - {{ pa.enderecoNumero }} -
            {{ pa.bairro }}</span
          >
        </div>
        <div class="info" v-if="pa.complemento">
          <label>Complemento:</label>
          <span>{{ pa.complemento }}</span>
        </div>
        <div class="info" v-if="pa.referencia">
          <label>Referência:</label>
          <span>{{ pa.referencia }}</span>
        </div>
        <div class="info">
          <label>Cidade/UF:</label>
          <span>{{ pa.cidade }} - {{ pa.uf }}</span>
        </div>
        <div class="info">
          <label>Telefone:</label>
          <span>
            {{ pa.telefone }}
          </span>
        </div>
        <div class="info" v-if="pa.whatsapp">
          <label>Whatsapp:</label>
          <span>
            {{ pa.whatsapp }}
          </span>
        </div>
        <div class="info" style="margin-top: 1em" v-if="pa.detalhes">
          <label>Detalhes:</label>
          <span style="color: blue">{{ pa.detalhes }}</span>
        </div>
      </template>
    </template>
  </p-card>
</template>

<script lang="ts">
import { CalcTipoEntrega } from "@/services/PedidoService";
import { PontoApoio, getInstance } from "@/services/PontoApoioService";
import { store, useStore } from "@/store";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { calcTipoEntregas, useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    const store = useStore();
    const control = useChecketouControl();

    const tipos = ref<CalcTipoEntrega[]>();
    async function loadTipos() {
      tipos.value = [];
      try {
        tipos.value = await calcTipoEntregas(
          control.endereco.value?.cep || store.state.user?.pessoa.cep!
        );
      } finally {
      }
    }

    function setTipoEntrega(f: CalcTipoEntrega) {
      control.pedidoInput.entrega.valor = f?.valor || 0;
      control.pedidoInput.entrega.prazo = f?.prazo || 0;
      control.pedidoInput.entrega.servico = f?.servico || null!;
      control.pedidoInput.entrega.extra = f?.extra || null!;
      control.pedidoInput.entrega.tipoEntrega = f?.tipoEntrega || null!;
      control.pedidoInput.entrega.tipoEntregaId = f?.tipoEntregaId || null!;
    }

    const pa = ref<PontoApoio>();
    const loading = ref(false);
    async function load() {
      pa.value = undefined;
      if (store.state.user?.pontoApoioCompra) {
        loading.value = true;
        try {
          pa.value = await getInstance().getById(
            store.state.user?.pontoApoioCompra.id
          );
          await loadTipos();
          const t = tipos.value?.find((t) => t.tipoEntrega.retirarLocal);
          t && setTipoEntrega(t);
        } finally {
          loading.value = false;
        }
      }
    }
    onMounted(load);

    return {
      loading,
      pa,
    };
  },
});
</script>

<style scoped>
.info {
  margin: 5px;
  display: flex;
}
.info label {
  display: inline-block;
  min-width: 100px;
  text-align: right;
  margin-right: 5px;
}
.info span {
  font-weight: bold;
}
</style>