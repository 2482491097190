<template>
  <div class="p-grid card">
    <h5
      class="p-d-flex p-flex-column p-flex-sm-row p-ai-center"
      style="width: 100%"
    >
      <span style="flex: 1; width: 100%; min-width: 200px">
        Fechar Pedido
        <p-progress-spinner v-if="loading" style="width: 15px; height: 15px" />
      </span>
      <div class="p-text-right" style="width: 100%">
        <p-button
          class="p-button-secondary p-mr-2"
          label="Voltar"
          @click="voltar"
        />
        <p-button
          v-if="$store.state.carrinhoItens.length"
          label="Continuar"
          @click="fecharPedido"
          :icon="saving ? 'pi pi-spin pi-spinner' : ''"
          :disabled="block"
        />
      </div>
    </h5>

    <Totais />

    <template v-if="$store.state.carrinhoItens.length">
      <div class="p-col-12 p-md-6" v-if="$store.state.user?.pontoApoioCompra">
        <EntregaPa />
      </div>
      <template v-else>
        <div class="p-col-12 p-md-6">
          <Endereco />
        </div>
        <div class="p-col-12 p-md-6">
          <Entrega />
        </div>
      </template>
      <!-- <div class="p-col-12 p-md-6">
        <Resumo />
      </div> -->
      <div class="p-col-12 p-md-6">
        <p-card>
          <template #title> Observação (opcional) </template>
          <template #content>
            <p-textarea
              style="width: 100%; height: 100px; color: red"
              v-model="control.pedidoInput.observacao"
            />
          </template>
        </p-card>
      </div>

      <Totais />

      <h5 class="p-d-flex p-ai-center p-jc-end" style="width: 100%">
        <p-button
          class="p-button-secondary p-mr-2"
          label="Voltar"
          @click="voltar"
        />
        <p-button
          label="Continuar"
          @click="fecharPedido"
          :icon="saving ? 'pi pi-spinner pi-spin' : ''"
          :disabled="block"
        />
      </h5>
    </template>
    <template v-else> Nenhum item no carrinho </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import Endereco from "./Endereco.vue";
import Entrega from "./Entrega.vue";
import EntregaPa from "./EntregaPa.vue";
import Resumo from "./Resumo.vue";
import { useRouter } from "vue-router";
import { fecharPedido, installControl } from "./shared";
import Totais from "./Totais.vue";
import { useToast } from "primevue/usetoast";
import { useStore } from "@/store";

export default defineComponent({
  components: {
    Endereco,
    Entrega,
    EntregaPa,
    Resumo,
    Totais,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const control = installControl();

    const toast = useToast();
    const saving = ref(false);

    const block = computed(() => {
      if (store.state.user?.pontoApoioCompra) {
        return false;
      }
      return !control.pedidoInput.entrega?.tipoEntrega || saving.value;
    });

    return {
      block,
      saving,
      loading: control.loadingItensCarrinho,
      control,
      async fecharPedido() {
        if (!store.state.carrinhoItens.length) {
          return;
        }
        saving.value = true;
        try {
          const entrega = control.pedidoInput.entrega;
          if (!entrega?.tipoEntrega) {
            toast.add({
              severity: "warn",
              summary: "Escolha uma forma de entrega",
              life: 2_000,
            });
            return;
          }
          if (
            entrega?.tipoEntrega.transportadora === "correios" &&
            !entrega.servico
          ) {
            toast.add({
              severity: "warn",
              summary: "Escolha um serviço de entrega",
              life: 2_000,
            });
            return;
          }
          const id = await fecharPedido(control);
          router.push({
            name: "pedido-checkout",
            params: {
              id,
            },
          });
        } finally {
          saving.value = false;
        }
      },
      voltar() {
        router.push({ name: "cart" });
      },
    };
  },
});
</script>
