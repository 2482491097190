<template>
  <p-dialog
    :header="`${end.id ? 'Editar' : 'Cadastrar'} Endereço`"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <form class="form" @submit.prevent="salvar">
      <div class="p-fluid">
        <div class="p-field">
          <label>Tipo:</label>
          <p-dropdown
            v-model="end.tipo"
            required
            optionLabel="name"
            optionValue="value"
            :options="enderecoTipos"
          />
        </div>
        <div class="p-field">
          <label>Destinatário:</label>
          <p-inputtext v-model="end.contato" required maxlength="30" />
        </div>
        <div class="p-field">
          <label style="display: block">
            CEP
            <i v-if="cep.loading.value" class="pi pi-spin pi-spinner"></i>
          </label>
          <InputMask
            v-model="end.cep"
            required
            mask="99999-999"
            @blur="cep.consultar(end.cep)"
            style="width: 100px"
          />
        </div>
        <div class="p-field">
          <label>Logradouro</label>
          <p-inputtext v-model="end.logradouro" required />
        </div>
        <div class="p-field">
          <label style="display: block">Número</label>
          <p-inputtext
            v-model="end.enderecoNumero"
            required
            style="width: 100px"
          />
        </div>
        <div class="p-field">
          <label>Complemento</label>
          <p-inputtext v-model="end.complemento" />
        </div>
        <div class="p-field">
          <label>Referência</label>
          <p-inputtext v-model="end.referencia" />
        </div>
        <div class="p-field">
          <label>Bairro</label>
          <p-inputtext v-model="end.bairro" required />
        </div>
        <div class="p-field">
          <label>Cidade</label>
          <p-inputtext
            v-model="end.cidade"
            required
            :disabled="cep.encontrou.value"
          />
        </div>
        <div class="p-field">
          <label>UF</label>
          <p-dropdown
            v-model="end.uf"
            required
            :options="ufs"
            optionLabel="nome"
            optionValue="sigla"
            :disabled="cep.encontrou.value"
          />
        </div>
      </div>

      <div class="p-d-flex p-flex-row-reverse p-jc-start">
        <p-button
          label="Salvar"
          :icon="`pi pi-save${saving ? ' p-spin' : ''}`"
          :disabled="saving"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import {
  getInstance,
  enderecoTipos,
  initialEndereco,
} from "@/services/EnderecoService";
import { defineComponent, PropType, ref, watch } from "vue";
import { useCadastro } from "@/composables/crud";
import { PessoaEndereco } from "@/services/PessoaService";
import { useBuscaCep } from "@/composables/cep";
import { ufs } from "@/utils/endereco";
import { clone } from "@/utils/object";

const status = ["ativa", "inativa", "suspensa"];

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  props: {
    endereco: Object as PropType<PessoaEndereco>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const saving = ref(false);

    const end = ref<PessoaEndereco>(clone(props.endereco || initialEndereco));

    watch(
      () => props.endereco,
      (e) => {
        end.value = clone(e || initialEndereco);
      }
    );

    function close() {
      if (!saving.value) {
        emit("update:visible", false);
      }
    }

    async function salvar() {
      if (!end.value) {
        return;
      }
      saving.value = true;
      try {
        await getInstance().store(end.value);
        emit("cadastrar");
        emit("update:visible", false);
        return true;
      } catch (e) {
        return false;
      } finally {
        saving.value = false;
      }
    }

    const cep = useBuscaCep((resp) => {
      if (end.value) {
        end.value.logradouro = resp.logradouro;
        end.value.complemento = resp.complemento;
        end.value.bairro = resp.bairro;
        end.value.cidade = resp.localidade;
        end.value.uf = resp.uf;
      }
    });

    return {
      end,
      enderecoTipos,
      ufs,
      cep,
      status,
      saving,

      close,
      salvar,

      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>