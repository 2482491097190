
import { useBuscaCep } from "@/composables/cep";
import { PessoaEndereco } from "@/services/PessoaService";
import { getService, User } from "@/services/UserService";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";
import { useChecketouControl } from "./shared";

export default defineComponent({
  emits: ["excluir"],
  props: {
    endereco: {
      type: Object as PropType<PessoaEndereco>,
      required: true,
    },
    principal: {
      type: Boolean,
      default: false,
    },
  },
  setup({ endereco, principal }) {
    const control = useChecketouControl();

    return {
      control,
      getClass() {
        return {
          "p-col-12": true,
          "p-md-6": true,
          "endereco-card": true,
          "endereco-card-checked": principal
            ? !control.endereco.value
            : control.endereco?.value?.id === endereco.id,
        };
      },
      selecionar() {
        control.endereco.value = principal ? undefined : endereco;
      },
    };
  },
});
