
import { CalcTipoEntrega } from "@/services/PedidoService";
import { useStore } from "@/store";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { calcTipoEntregas, useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    const control = useChecketouControl();
    const store = useStore();

    const frete = ref<CalcTipoEntrega>();
    const tipos = ref<CalcTipoEntrega[]>();
    const loadingFrete = ref(false);
    async function load() {
      frete.value = undefined;
      tipos.value = [];
      loadingFrete.value = true;
      try {
        tipos.value = await calcTipoEntregas(
          control.endereco.value?.cep || store.state.user?.pessoa.cep!
        );
      } finally {
        loadingFrete.value = false;
      }
    }
    watch(control.endereco, async (e) => {
      load();
    });

    onMounted(load);

    watch(frete, (f) => {
      control.pedidoInput.entrega.valor = f?.valor || 0;
      control.pedidoInput.entrega.prazo = f?.prazo || 0;
      control.pedidoInput.entrega.servico = f?.servico || null!;
      control.pedidoInput.entrega.extra = f?.extra || null!;
      control.pedidoInput.entrega.tipoEntrega = f?.tipoEntrega || null!;
      control.pedidoInput.entrega.tipoEntregaId = f?.tipoEntregaId || null!;
    });

    function getCompraMinimaCompesado(frete: CalcTipoEntrega) {
      return store.getters.isCompraPa ?
        frete.compfreteCompraMinPa : frete.compfreteCompraMinAfiliado;
    }

    return {
      loadingFrete,
      tipos,
      frete,
      getCompraMinimaCompesado
    };
  },
});
