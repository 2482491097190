<template>
  <p-card>
    <template #title>Forma de Entrega</template>
    <template #content>
      <p-progress-spinner v-if="loadingFrete" />
      <template v-else>
        <div v-if="tipos">
          <div class="p-field-radiobutton" v-for="(t, i) in tipos" :key="i">
            <p-radiobutton
              :disabled="t.erro"
              :id="`tipo_${i}`"
              :value="t"
              v-model="frete"
            />
            <label :for="`tipo_${i}`">
              <span v-if="!t.erro">{{ $formatCurrency(t.valor) }} -</span>
              {{ t.servico }}
            </label>
            <label v-if="t.erro" style="color: red">{{ t.erro }}</label>
            <template v-else>
              <label
                v-if="t.prazo && t.prazoMin"
              >(De {{ t.prazoMin }} a {{ t.prazo }} dias úteis)</label>
              <label v-else-if="t.prazo">(Média de {{ t.prazo }} dias)</label>
            </template>
          </div>
          <div
            style="color: red; font-weight: bold;"
            v-if="frete?.tipoEntrega?.retirarLocal"
          >Favor informar como quer fazer a retirada na observação.</div>
          <div
            style="color: blue; font-weight: bold;"
            v-if="frete?.compensarFrete"
          >
            Nesta opção de frete, as compras com valor em produtos acima de
            {{ $formatCurrency(getCompraMinimaCompesado(frete) || 0) }}
            terão o custo do frete compensado em mercadoria extra.
          </div>
        </div>
        <div v-else>Nenhum tipo de entrega configurado</div>
      </template>
    </template>
  </p-card>
</template>

<script lang="ts">
import { CalcTipoEntrega } from "@/services/PedidoService";
import { useStore } from "@/store";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { calcTipoEntregas, useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    const control = useChecketouControl();
    const store = useStore();

    const frete = ref<CalcTipoEntrega>();
    const tipos = ref<CalcTipoEntrega[]>();
    const loadingFrete = ref(false);
    async function load() {
      frete.value = undefined;
      tipos.value = [];
      loadingFrete.value = true;
      try {
        tipos.value = await calcTipoEntregas(
          control.endereco.value?.cep || store.state.user?.pessoa.cep!
        );
      } finally {
        loadingFrete.value = false;
      }
    }
    watch(control.endereco, async (e) => {
      load();
    });

    onMounted(load);

    watch(frete, (f) => {
      control.pedidoInput.entrega.valor = f?.valor || 0;
      control.pedidoInput.entrega.prazo = f?.prazo || 0;
      control.pedidoInput.entrega.servico = f?.servico || null!;
      control.pedidoInput.entrega.extra = f?.extra || null!;
      control.pedidoInput.entrega.tipoEntrega = f?.tipoEntrega || null!;
      control.pedidoInput.entrega.tipoEntregaId = f?.tipoEntregaId || null!;
    });

    function getCompraMinimaCompesado(frete: CalcTipoEntrega) {
      return store.getters.isCompraPa ?
        frete.compfreteCompraMinPa : frete.compfreteCompraMinAfiliado;
    }

    return {
      loadingFrete,
      tipos,
      frete,
      getCompraMinimaCompesado
    };
  },
});
</script>
