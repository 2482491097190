
import {
  getInstance,
  enderecoTipos,
  initialEndereco,
} from "@/services/EnderecoService";
import { defineComponent, PropType, ref, watch } from "vue";
import { useCadastro } from "@/composables/crud";
import { PessoaEndereco } from "@/services/PessoaService";
import { useBuscaCep } from "@/composables/cep";
import { ufs } from "@/utils/endereco";
import { clone } from "@/utils/object";

const status = ["ativa", "inativa", "suspensa"];

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  props: {
    endereco: Object as PropType<PessoaEndereco>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const saving = ref(false);

    const end = ref<PessoaEndereco>(clone(props.endereco || initialEndereco));

    watch(
      () => props.endereco,
      (e) => {
        end.value = clone(e || initialEndereco);
      }
    );

    function close() {
      if (!saving.value) {
        emit("update:visible", false);
      }
    }

    async function salvar() {
      if (!end.value) {
        return;
      }
      saving.value = true;
      try {
        await getInstance().store(end.value);
        emit("cadastrar");
        emit("update:visible", false);
        return true;
      } catch (e) {
        return false;
      } finally {
        saving.value = false;
      }
    }

    const cep = useBuscaCep((resp) => {
      if (end.value) {
        end.value.logradouro = resp.logradouro;
        end.value.complemento = resp.complemento;
        end.value.bairro = resp.bairro;
        end.value.cidade = resp.localidade;
        end.value.uf = resp.uf;
      }
    });

    return {
      end,
      enderecoTipos,
      ufs,
      cep,
      status,
      saving,

      close,
      salvar,

      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
