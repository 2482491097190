
import { getService, User } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    const control = useChecketouControl();

    return {
      loading: control.loading,
      pedidoInput: control.pedidoInput,
    };
  },
});
