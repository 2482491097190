
import { CalcTipoEntrega } from "@/services/PedidoService";
import { PontoApoio, getInstance } from "@/services/PontoApoioService";
import { store, useStore } from "@/store";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { calcTipoEntregas, useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    const store = useStore();
    const control = useChecketouControl();

    const tipos = ref<CalcTipoEntrega[]>();
    async function loadTipos() {
      tipos.value = [];
      try {
        tipos.value = await calcTipoEntregas(
          control.endereco.value?.cep || store.state.user?.pessoa.cep!
        );
      } finally {
      }
    }

    function setTipoEntrega(f: CalcTipoEntrega) {
      control.pedidoInput.entrega.valor = f?.valor || 0;
      control.pedidoInput.entrega.prazo = f?.prazo || 0;
      control.pedidoInput.entrega.servico = f?.servico || null!;
      control.pedidoInput.entrega.extra = f?.extra || null!;
      control.pedidoInput.entrega.tipoEntrega = f?.tipoEntrega || null!;
      control.pedidoInput.entrega.tipoEntregaId = f?.tipoEntregaId || null!;
    }

    const pa = ref<PontoApoio>();
    const loading = ref(false);
    async function load() {
      pa.value = undefined;
      if (store.state.user?.pontoApoioCompra) {
        loading.value = true;
        try {
          pa.value = await getInstance().getById(
            store.state.user?.pontoApoioCompra.id
          );
          await loadTipos();
          const t = tipos.value?.find((t) => t.tipoEntrega.retirarLocal);
          t && setTipoEntrega(t);
        } finally {
          loading.value = false;
        }
      }
    }
    onMounted(load);

    return {
      loading,
      pa,
    };
  },
});
