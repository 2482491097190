import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { CrudService, PageConsult, PageConsultInput } from "./Services";
import { baseURL } from "@/api/rest";
import { clone, filterKeys, removeKeys } from "@/utils/object";
import { PessoaEndereco } from "./PessoaService";

let instance: EnderecoService;

export const enderecoTipos = [
  { name: "Comercial", value: "comercial" },
  { name: "Residencial", value: "residencial" },
];

export const initialEndereco: Readonly<PessoaEndereco> = Object.freeze({
  id: null!,
  excluido: false,
});

export function getInstance(): EnderecoService {
  if (!instance) {
    instance = new EnderecoService();
  }
  return instance;
}

export default class EnderecoService {
  private prepareData(input: PessoaEndereco) {
    const data = removeKeys(clone(input), [
      "__typename",
    ]);
    return data;
  }

  async store(input: PessoaEndereco): Promise<number> {
    const result = await client
      .mutate({
        variables: {
          input: this.prepareData(input),
        },
        mutation: gql`
          mutation($input: PessoaEnderecoInput!) {
            setEndereco(input: $input) {
              id
            }
          }
        `,
      });
    const { data: { setEndereco: { id } } } = result;
    return id;
  }
}
