
import { PessoaEndereco } from "@/services/PessoaService";
import { getService, User } from "@/services/UserService";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import EnderecoCard from "./EnderecoCard.vue";
import CadastroEndereco from "./CadastroEndereco.vue";
import { getInstance } from "@/services/EnderecoService";
import { useConfirm } from "primevue/useconfirm";
import { useChecketouControl } from "./shared";

export default defineComponent({
  components: {
    EnderecoCard,
    CadastroEndereco,
  },
  setup() {
    const control = useChecketouControl();

    const user = ref<User>();
    const loading = ref(false);
    async function load() {
      loading.value = true;
      try {
        user.value = await getService().getEnderecos();
      } finally {
        loading.value = false;
      }
    }
    onMounted(() => {
      load();
    });
    const enderecoPrincipal = computed(
      (): PessoaEndereco => {
        const p = user.value?.pessoa;
        return {
          id: null!,
          tipo: null!,
          cep: p?.cep,
          logradouro: p?.logradouro,
          enderecoNumero: p?.enderecoNumero,
          complemento: p?.complemento,
          referencia: p?.referencia,
          bairro: p?.bairro,
          cidade: p?.cidade,
          cidadeIbge: null!,
          uf: p?.uf,
          contato: user.value?.name,
          longitude: null!,
          latitude: null!,
        };
      }
    );

    const dialog = ref(false);
    const selecionado = ref<PessoaEndereco>();
    watch(dialog, (v) => !v && (selecionado.value = undefined));

    const confirm = useConfirm();

    return {
      control,
      user,
      enderecoPrincipal,
      loading,
      load,

      selecionado,
      dialog,
      novo() {
        selecionado.value = undefined;
        dialog.value = true;
      },
      excluir(e: PessoaEndereco) {
        confirm.require({
          message: "Tem certeza que deseja remover o endereço?",
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            try {
              e.excluido = true;
              await getInstance().store(e);
              load();
            } catch (e) {
              console.log("Falha ao excluir endereço", e);
            }
          },
        });
      },
    };
  },
});
